.footer-menu {
  &__item {
    margin: 0 0 25px;
    padding: 0;

    &:last-child {
      margin-bottom: 0; }

    @include mmin($tablet) {
      margin: 0;
      margin-right: 27px;

      &:last-child {
        margin-right: 0; } } }

  &__link {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    transition: color .3s;

    &:hover {
      color: $brand-color; }

    @include mmin($tablet) {
      font-size: 15px;
      font-weight: 500; } } }
