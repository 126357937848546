.contact-us {
  position: relative;
  perspective: 600px;
  color: $main-color;

  &__wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;

    &.flip {
      transform: rotateY(180deg); } }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 50px;

    @include mmin($tablet) {
      font-size: 24px;
      margin: 0 0 60px; } }

  &__form {
    left: 0;
    right: 0;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    transition: all .4s; } }

.contact-form {
  display: flex;
  flex-flow: row wrap;
  max-width: 575px;
  margin: 0 auto 50px;
  background-color: #fff;

  @include mmin($tablet) {
    margin: 0 auto 60px; } }

.form-group {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  &.has-error {
    margin-bottom: 45px; }

  //&.field-contactform-body
  //.help-block


  &--filled {
    .control-label {
      transform: translate3d(0,-5px,0); } }

  &__wrap {
    position: relative;
    width: 100%; }

  &__control {
    width: 100%;
    border: 1px solid #E1E1E1;
    height: 50px;
    font-weight: 300;
    line-height: 25px;
    font-size: 15px;
    letter-spacing: 0.02em;
    padding: 0 15px;
    color: $main-color;

    @include mmin($tablet) {
      padding-left: 20px;
      padding-right: 20px; }

    @include placeholder(#909090);

    &:focus {
      border-color: #1F1F1F; }

    &--textarea {
      resize: none;
      padding-top: 20px;
      height: 130px; } }

  &.has-error {
    .form-group__control {
      border-color: $error-msg; }

    .help-block {
      display: block; } } }

.control-label {
  //padding: 0 2px
  font-size: 16px;
  font-weight: 400;
  color: $main-color;
  width: 100%;
  //transform: translate3d(0,29px,0)
  transform: translate3d(0,15px,0);
  transition: all .3s ease-in-out;
  position: absolute;
  padding: 6px 0;
  cursor: pointer; }

.help-block {
  display: none;
  position: absolute;
  //bottom: 20px
  color: $error-msg;
  margin: 0;
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0.02em; }

.form-group-column {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .form-group {
    width: 100%;

    @include mmin($tablet) {
      width: calc(50% - 10px); } } }
