.catalog {

  &-filter-wrap {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;

    @include mmin(601) {
      flex-flow: row wrap;
      justify-content: space-between; } }

  &-filter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;

    &:nth-of-type(1) {
      order: 2;

      .catalog-filter-btn {
        background-color: $brand-color;
        border-color: $brand-color;
        color: $main-color;

        &:disabled {
          cursor: not-allowed;
          //opacity: .7
          background-color: transparent;
          border-color: #AFAFAF;
          color: #AFAFAF; }

        &.active {
          color: $main-color;
          background-color: transparent;
          border-color: $brand-color;
          cursor: pointer; } }

      //+mmin($tablet)
 }      //margin-right: 60px

    &:nth-of-type(2) {
      order: 1;
      margin-bottom: 30px;

      @include mmin($large) {
        margin-bottom: 0; } }

    @include mmin(601) {
      justify-content: flex-start;

      &:first-of-type {
        margin-bottom: 0; } } }

  &-filter {
    &__choose-title {
      font-size: 18px;
      margin: 0 0 50px;
      font-weight: 400;
      width: 100%;
      text-align: center;

      @include mmin($tablet) {
        text-align: left;
        margin: 0 0 60px;
        font-size: 24px; } }

    &__title {
      color: $main-color;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      margin: 0 0 20px;
      text-align: center;

      @include mmin($small) {
        font-size: 18px;
        width: inherit;
        margin-right: 10px;
        margin-bottom: 0;
        text-align: left; } } } }

.catalog-filter-btn {
  width: 40px;
  height: 40px;
  border: 2px solid $brand-color;
  padding: 0;
  outline: none;
  border-radius: 50%;
  color: $main-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  margin-right: 18px;
  margin-bottom: 15px;
  background-color: $brand-color;
  cursor: pointer;

  @include mmin($small) {
    font-size: 18px;
    line-height: 46px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 0; }

  &.active {
    background-color: transparent;
    color: $main-color; }

  &:last-child {
    margin-right: 0; } }
