.latest-news {
  &__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch; }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 50px;
    color: $main-color;
    text-align: center;

    @include mmin($tablet) {
      text-align: left;
      margin-bottom: 60px;
      font-size: 24px; } } }
