.faq-tabs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @include mmin($tablet) {
    flex-flow: row wrap;
    margin: 0 0 60px; }

  &__item {
    padding: 0 25px;
    text-align: center;
    align-self: center;

    @include mmin($tablet) {
      padding: 0 50px; }

    &.active {
      .faq-tabs-list__link {
        font-weight: 700;
        color: $main-color; } } }

  &__link {
    font-size: 14px;
    text-decoration: none;
    color: #848484;

    @include mmin($tablet) {
      font-size: 18px; } } }
