.certificate {
  padding-bottom: 104px;

  &__title {
    display: none; }

  &__video {
    margin-bottom: 60px; }

  &__advantage {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }

  &-content {
    &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 60px; }

    &__img {
      display: block;
      margin-bottom: 50px; }

    &__title {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 25px; } } }

@import "certificate-advantage";
@import "youtube-block";
