.card-advanced {
  &__title {
    font-size: 15px;
    font-weight: 500;
    color: $main-color;
    line-height: 23px;
    margin: 0 0 18px;
    display: none;

    //+mmin($tablet--hor)
 }    //display: block

  &__value {
    font-weight: 400; }

  &__btn {
    @include mmin($tablet--hor) {
      margin-top: 25px; } } }
