.cooperation {
  &-header {
    //background-image: url("../img/cooperation-bg.jpg")
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    min-height: 382px;
    display: flex;
    flex-flow: row wrap;

    overflow: hidden;

    &__wrap {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      @include mmin($small) {
        position: static;
        align-self: center; } }

    &__content {
      width: auto;

      background-color: rgba(5, 14, 25, .5);
      position: relative;
      z-index: 5;
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      padding-left: 27px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px;

      @include mmin($small) {
        display: block;
        background-color: transparent;
        position: static;
        align-self: center;
        width: 48%;
        padding: 0 0 0 12px;
        margin: 0; } }

    &__image {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 0;

      @include mmin($small) {
        position: static;
        transform: none;
        width: 48%; }

      img {
        margin: 0 auto;
        display: block;
        vertical-align: middle; } }

    &__title {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 0 20px;
      color: #fcfcfc; }

    &__description {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        height: 300px;
        background-color: #929292;
        left: 2px;
        top: 112%; }

      p {
        margin: 0; } } }

  &-context {
    //padding-top: 60px
    padding-bottom: 120px;

    overflow: hidden;

    &__wrap {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between; }

    &__list {
      width: 100%;
      margin-bottom: 50px;

      @include mmin($small) {
        width: 49%;
        margin-bottom: 0; }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        counter-reset: issues 0;

        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 665px;
          background-color: #929292;
          bottom: 0;
          left: 14px; }

        li {
          padding-left: 45px;
          margin-bottom: 30px;
          position: relative;
          background-color: #fff;
          z-index: 5;
          min-height: 35px;
          display: flex;
          align-items: center;

          &::before {
            counter-increment: issues 1;
            content: counter(issues, decimal);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            text-align: center;
            border: 1px solid $main-color;
            border-radius: 50%;
            font-size: 16px;
            line-height: 28px;
            font-weight: 500;
            width: 30px;
            height: 30px; }

          &:last-child {
            margin-bottom: 0; } } } }

    &__manager-block {
      width: 100%; } }

  &-manager {
    &__detail-info {
      position: relative;
      max-width: 100%;
      margin: 0 auto 50px;
      padding-left: 75px;
      line-height: 23px;
      font-size: 15px;
      letter-spacing: 0.02em;

      @include mmin($small) {
        margin: 0 auto 32px;
        max-width: 355px; }

      &::before {
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        background-image: url("../img/more-info.png");
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%); } }

    &__employee {
      @include mmin($tablet--hor) {
        justify-content: center; } }

    &__manager-info {
      background-color: #ebebeb;
      padding: 32px 20px 40px 30px;
      color: $main-color;
      font-size: 16px;
      max-width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-left: auto;

      @include mmin($small) {
        max-width: 355px; } }

    &__title {
      font-weight: 500;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 12px; }

    &__name {
      width: 100%;
      margin: 0 0 30px; }

    &__tel {
      width: 100%;
      margin-bottom: 15px;

      @include mmin($small) {
        width: inherit;
        margin-bottom: 0; } }

    &__name, &__tel, &__email {
      font-weight: 300; } } }
