.faq-form {
  padding-bottom: 60px;

  @include mmin($tablet) {
    padding-bottom: 100px; }

  &__title {
    color: $main-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 50px;
    text-align: center;

    @include mmin($tablet) {
      margin: 0 0 60px;
      font-size: 24px; } }

  &__question-form {
    margin-bottom: 0 !important;

    &.hidden {
      display: none; } }

  &__alert {
    border-radius: 4px;
    border: 1px solid $main-color;
    font-size: 16px;
    font-weight: 400;
    color: $main-color;
    width: 100%;
    max-width: 475px;
    margin: 0 auto;
    text-align: center;
    padding: 45px 5px;
    margin-top: 75px;
    margin-bottom: 35px;

    + .faq-form__title {
      display: none; } } }
