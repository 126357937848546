//brand
.main-brand {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 50px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;

      &:last-child {
        margin-bottom: 0; }

      &:nth-child(2n) {
        flex-flow: row-reverse wrap;

        ul {
          li {
            @include mmin($tablet) {
              padding-left: 0;
              padding-right: 60px; } } } }

      &:nth-child(2n+1) {
        flex-flow: row wrap;

        ul {
          li {
            @include mmin($tablet) {
              padding-left: 60px;
              padding-right: 0; } } } }

      img {
        vertical-align: middle;
        display: block;
        margin: 0 auto;
        object-fit: contain;
        max-height: 224px;

        @include mmin($tablet) {
          width: 50%;
          object-fit: inherit;
          max-height: inherit; }

        @include mmin($tablet--hor) {
          width: 60%;
          height: 450px; } }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        line-height: 24px;
        color: #fff;
        font-size: 15px;
        letter-spacing: 0.02em;
        flex-flow: column wrap;

        @include mmin($tablet) {
          width: 50%; }

        @include mmin($tablet--hor) {
          width: 40%; }

        li {
          width: auto;
          align-self: center;
          padding: 30px 0 0;
          margin-bottom: 30px;

          @include mmin($tablet--hor) {
            padding: 0 15px; } } } } } }
