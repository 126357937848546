.catalog {
  padding-bottom: 60px;

  //&__products-text
  //font-size: 24px
  //color: $main-color


  &-products {
    margin-top: 60px;

    &__text {
      font-size: 16px;
      line-height: 20px; }

    &__wrap {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 60px; } } }

@import "catalog-filter";
@import "product-card";
