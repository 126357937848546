.disc-container {
  padding-top: 150px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include mmin($tablet) {
    //padding-top: 150px
    padding-bottom: 60px; }

  &__title {
    margin-bottom: 50px;
    width: 100%;

    @include mmin($tablet) {
      margin-bottom: 100px; } }

  &__col {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0; }

    @include mmin($tablet) {
      width: 31%;
      margin-bottom: 0; }

    a {
      display: block;
      transition: all .3s;

      &:hover {
        img {
          @include mmin($tablet) {
            transform: scale(1.1); } }
        p {
          color: $brand-color; } } }

    img {
      transform: scale(1);
      transition: transform .3s;
      width: 110px;
      height: 110px;

      @include mmin($tablet) {
        width: 150px;
        height: 150px; } }

    p {
      margin: 20px 0 0;
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      text-align: center;

      @include mmin($tablet) {
        margin: 50px 0 0; } } } }
