.breadcrumb {
  padding: 50px 0;
  margin: 0;
  list-style-type: none;
  color: #000000;
  font-size: 14px;

  @include mmin($tablet) {
    padding: 60px 0; }

  @include mmin($tablet--hor) {
    font-size: 16px; }

  li {
    position: relative;
    margin-right: 19px;
    display: inline-block;

    &.active {
      span {
        font-weight: 500; } } }

  li:not(:last-of-type)::after {
    content: '';
    width: 12px;
    height: 12px;
    background-image: url(../img/svg/breadcrumbs-arrow.svg);
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -16px; }

  li a {
    color: $main-color;
    font-weight: 300;
    text-decoration: none;
    transition: color .3s;

    //&:hover
 } }    //color: $second-color
