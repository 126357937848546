.mobile-menu {
  &__header {
    padding: 23px 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%; }

  &__logo {
    img {
      max-width: 69px; } }

  &__content {
    overflow-x: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    position: absolute;
    min-width: 270px;
    top: 0;
    left: 0;
    width: 270px;
    height: 100vh;
    padding: 0 0 43px;
    transform: translateX(-100%);
    background-color: #fff;
    transition: transform .3s;
    z-index: 12;

    @include mmin(376) {
      width: 320px; }

    @include mmin($tablet) {
      width: 40vw; } }

  &__phone {
    color: $main-color;
    font-size: 18px;
    font-weight: 700;
    padding: 19px 20px 19px 20px;
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    width: 100%;

    .phone-icon {
      display: flex;
      margin-right: 15px; } }

  &__search.search {
    position: relative;
    display: block;
    padding: 0 20px; }

  .search {
    &__icon svg {
      fill: white; }

    &__form {
      position: relative;
      transform: none;
      background-color: transparent;
      margin-bottom: 10px; }

    &__input-box {
      height: auto;
      position: relative; }

    &__input {
      border: 1px solid #fff;
      color: #fff;
      padding: 0 30px 0 25px;
      width: 100%; }

    &__button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1; } } }

.mobile-nav {
  width: 100%;

  &__list {
    display: flex;
    flex-flow: column wrap;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none; }


  &__item {
    padding: 19px 20px 19px 20px;
    position: relative;
    border-bottom: 1px solid #E1E1E1;

    &:first-child {
      border-top: 1px solid #E1E1E1; } }

  &__link {
    display: block;
    color: $main-color;
    font-size: 16px;
    font-weight: 500; } }

.lang-list-mob {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;

  &__item {
    width: 52px;
    height: 34px;
    color: $main-color;
    text-transform: uppercase;
    font-size: 14px; }

  &__active, &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 34px;
    color: $main-color;
    text-transform: uppercase;
    font-size: 14px; }

  &__active {
    background-color: $brand-color; }

  &__link {
    background-color: #fff; } }

.search-mob-open {
  background-color: #fff;

  &::after {
    content: '';
    position: fixed;
    left: 0;
    top: 84px;
    z-index: 12;
    background: rgba(0,0,0,.6);
    width: 100%;
    height: 100%; }

  .search__form {
    left: 0;
    top: 21px;
    padding: 0 0 0 20px;
    z-index: 16;
    display: block; }

  .search__input-box {
    width: calc(100% - 60px);
    position: relative;
    height: 40px;

    &::after {
      content: '';
      background-color: #E1E1E1;
      width: 220px;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0; } }

  .search__input {
    padding-right: 60px;
    width: 100%;
    font-size: 18px;
    height: 40px;
    line-height: 40px; }

  .search__icon svg {
    fill: $main-color; }

  .search__button {
    right: 78px;
    top: 31px;
    z-index: 17 !important;
    position: absolute; }

  .close-search {
    display: block;
    margin-right: 20px;
    position: absolute;
    top: 28px;
    z-index: 16;
    margin-top: 0;

    svg {
      stroke: $main-color; } } }
