.feedback {
    font-size: 0;
    max-width: 100%;
    background-color: #fff;
    padding: 20px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    transform: rotateY( 180deg );
    backface-visibility: hidden;

    &__logo {

        margin: 0 auto;
        display: inline-block;
        order: 1;
        align-self: center;
        text-align: center;
        width: 100%;

        img {
          max-width: 130px;
        }
    }
}
.feedback-content {
    order: 2;
    max-width: 100%;
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    color: $main-color;

    &__greeting {
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 25px;
        line-height: 1.5;
    }

    &__text {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 25px;
        line-height: 1.5;

        &--success,
        &--error {
            display: none;
        }
    }

    &__btn {
        display: inline-block;
        align-self: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__thx {
        margin-top: 90px;
        color: $main-color;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 36px;
        display: none;
    }

    &__success,
    &__error {
        display: none;
    }
    &__error {
        margin-top: 130px;
    }

}

.feedback-content--questions {
    max-width: 100%;
    width: 100%;

    @include mmin(768) {
        min-width: 100%;
    }
}

.feedback--questions {
    margin: 0;
    padding: 0;
}

.feedback-content__text--questions {
    margin-top: 27px;
    color: $main-color;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}
