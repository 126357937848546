.main-error-page {
  background-image: url('../img/error-page-bg-1.jpg');
  background-repeat: no-repeat;
  //background-size: cover
  //background-position: 50% 100%

  .site-main {
    background-color: transparent; } }

.error-page {
  display: flex;
  flex-flow: row wrap;
  min-height: calc(100vh - 475px);
  @include mmin($tablet--hor) {
    min-height: calc(100vh - 287px); }

  &__image {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 50px;

    @include mmin($tablet--hor) {
      padding-top: 80px;
      margin-bottom: 60px; }

    svg {
      width: 100%;
      max-height: 130px;

      @include mmin($tablet--hor) {
        max-height: 290px; } } }

  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 50px;
    text-align: center;

    @include mmin($tablet--hor) {
      font-size: 36px;
      margin-bottom: 55px; } }

  &__subtitle {
    display: block;
    font-weight: 700;
    font-size: 18px;

    @include mmin($tablet--hor) {
      font-size: 36px; } }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.03em;
    text-align: center;

    @include mmin($tablet--hor) {
      font-size: 24px;
      text-align: left; } }

  &-list {
    &__item {
 }      //margin-bottom: 15px

    &__link {
      color: #fff;
      text-decoration: underline; } }

  &__search {
    color: #fff;
    font-size: 15px;
    display: none; }

  &__message {
    display: inline-block;
    margin-bottom: 15px; }

  &__content {
    max-width: 780px;
    margin: 0 auto;
    padding-bottom: 30px; } }

.search-error {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;

  &__form {
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: all 700ms;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    border-radius: 4px;
    border: 0;
    //border: 1px solid #050e19
    position: relative; }

  &__input-box {
    margin: 0;
    transition: 1.5s;
    height: 38px;
    display: inline-block;
    padding: 0;
    width: 100%; }


  &__input {
    padding: 0 40px 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: #0c0c0c;
    outline: 0;
    border: 0;
    height: 38px;
    border-radius: 4px;
    width: 100%; }

  &__button {
    padding: 0;
    border: 0;
    background-color: transparent;
    transition: .4s;
    outline: 0;
    cursor: default;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    margin-right: 5px; }

  &__icon {
    width: 30px;
    height: 30px;

    svg {
      width: 22px;
      height: 22px;
      fill: $main-color;
      vertical-align: middle; } } }

//.error-page {
//  padding: 25px 0 0;
//
//  &__title {
//    color: $main-color;
//    font-size: 24px;
//    font-weight: 500;
//    line-height: 28px;
//    text-transform: uppercase;
//    margin-top: 0;
//    margin-bottom: 40px;
//
//    @include mmin($tablet) {
//      font-size: 36px;
//      line-height: 42px;
//  }
//}
//
//  &__content {
//    position: relative;
//    z-index: 1;
//    @include mmin($tablet) {
//      padding: 20px 50% 20px 0px;
//  }
//}
//
//  &__content::before {
//    content: "";
//    display: inline-block;
//    width: 100%;
//    height: 100%;
//    position: absolute;
//    top: 0;
//    right: 0;
//    z-index: 0;
//    opacity: 0.75;
//
//    background-image: url('../img/404.png');
//    background-repeat: no-repeat;
//    background-position: 100% 0px;
//    background-size: 100%;
//
//    @include mmin($tablet) {
//      background-size: 50%;
//      opacity: 1;
//  }
//}
//
//  &__options {
//    font-size: 18px;
//    font-weight: 500;
//    color: #000000;
//    line-height: 20px;
//    position: relative;
//    z-index: 1;
//
//    ul {
//      @include list-unstyled;
//      margin-bottom: 25px;
//
//      li::before {
//        content: '-';
//        display: inline-block;
//        margin-right: 5px;
//    }
//  }
//
//    a {
//      color: $main-color;
//  }
//}
//
//  &__search {
//    position: relative;
//    z-index: 1;
//}
//
//  &__message {
//    font-size: 18px;
//    font-weight: 500;
//    line-height: 20px;
//}
//}
