.certificate-advantage {
  margin-bottom: 50px;

  @include mmin($tablet) {
    margin-bottom: 60px; }

  &__col {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0; }

    @include mmin($small) {
      width: 49%; }

    @include mmin($tablet) {
      width: calc(25% - 30px);
      margin-bottom: 0; } }

  &__img {
    height: 100px; }

  p {
    margin: 30px auto 0;
    color: $main-color;
    font-size: 16px;
    max-width: 278px;

    @include mmin($tablet) {
      max-width: 100%;
      margin: 57px 0 0; } } }
