.contacts-employee {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 50px;

  @include mmin($tablet) {
    margin-bottom: 60px; }

  @include mmin($tablet--hor) {
    justify-content: space-between; }

  &__manager {
    width: 100%;
    min-height: 188px;

    @include mmin($small) {
      width: 80%; }

    @include mmin($tablet) {
      width: 65%;
      height: 267px; }

    @include mmin($tablet--hor) {
      width: calc(50% - 10px); } }

  &__title {
    font-size: 14px;
    font-weight: 300;
    margin: 0;

    @include mmin($tablet) {
      font-size: 16px; } } }

.manager {
  display: flex;
  flex-flow: row wrap;
  background-color: #fff;
  box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);
  color: $main-color;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0; }

  &__col-info {
    width: 57%;
    padding: 19px 5px 15px 20px;
    display: flex;
    flex-flow: column wrap;

    @include mmin($tablet) {
      width: 62%;
      padding: 40px 10px 30px 40px; } }

  &__col-photo {
    width: 43%;
    display: flex;
    justify-content: flex-end;

    @include mmin($tablet) {
      width: 38%; }

    @include mmin($tablet--hor) {
      justify-content: flex-start; } }

  &__name {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;

    @include mmin($tablet) {
      font-size: 24px; } }

  &__phone, &__mail {
    font-size: 14px;
    display: flex;
    align-items: center;

    @include mmin($tablet) {
      font-size: 18px; } }

  &__mail {
    margin-top: auto;
    margin-bottom: 19px; }

  &__icon {
    margin-right: 9px;
    display: flex;

    @include mmin($tablet) {
      margin-right: 20px; }

    img {
      width: 14px;
      height: 14px;

      @include mmin($tablet) {
        width: 20px;
        height: 20px; } } }

  &__employee-photo {
    align-self: flex-end;
    width: 150px;

    @include mmin($tablet) {
      width: 220px;
      height: 220px; } } }
