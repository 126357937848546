.btn {
  display: block;
  width: 219px;
  height: 42px;
  background-color: $brand-color;
  text-decoration: none;
  margin: 0;
  color: $main-color;
  font-size: 15px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: center;
  position: relative;
  border: 2px solid $brand-color;
  transition: background-color .3s;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    background-color: #fff; }

  &--product {
    width: 100%;

    @include mmin($tablet--hor) {
      width: 219px;
      margin: auto auto 0; } }

  &--form {
    width: 100%;
    height: 42px;

    @include mmin($tablet) {
      width: 300px;
      height: 50px; } }

  &--desc {
    margin: 0 auto;

    @include mmin($tablet) {
      margin: 0; } }

  &--main-brand {
    font-weight: 700;
    font-size: 15px;
    border: 2px solid $brand-color;
    color: #141414;
    background-color: $brand-color;

    @include mmin($tablet) {
      height: 50px;
      width: 250px;
      line-height: 46px; }

    &:hover {
      background-color: transparent;
      color: #fff; } } }
