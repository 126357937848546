.product-slider {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  @include mmin (768) {
    width: 51%;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 250px;

    @include mmin ($tablet) {
      height: 500px;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: .4s all ease-in-out;
    visibility: hidden;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__img img {
    width: auto;
    height: auto;
    max-height: 250px;
    margin: 0 auto;
    cursor: pointer;

    @include mmin ($tablet) {
      max-height: 500px;
    }
  }

  &__next,
  &__prev {
    position: absolute;
    background-color: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    top: 50%;
    transition: .4s all ease-in-out;
    display: none;
    margin-top: -15px;

    svg {
      vertical-align: middle;
      width: 30px;
      height: 30px;
      //fill: $main-color;
    }
  }

  &__prev {
    left: 10px;
    transform: rotate(-180deg);

    @include mmin ($tablet) {
      left: 15px;
    }
  }

  &__next {
    right: 10px;

    @include mmin ($tablet) {
      right: 15px;
    }
  }

  &__dots {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    transition: .4s all ease-in-out;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #a7a7a7;
    display: inline-block;
    margin: 0 6.5px;
  }

  &__dot--active {
    transform: scale(1.5);
    transform-origin: 50%;
    background-color: $main-color;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
