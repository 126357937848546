.product-description {
    display: flex;
    background-image: url(../img/product/product-bg.png);
    background-size: cover;
    background-position: 50%;
    margin-bottom: 30px;
    flex-direction: column;

    @include mmin(768) {
        flex-direction: row;
    }

    &__items {
        background-color: rgba(0, 0, 0, .6);
        height: 150px;
        width: 100%;
        position: relative;
        transition: all .4s;
        text-align: center;
        display: flex;
        justify-content: space-between;
        padding: 25px;

        @include mmin(768) {
            justify-content: center;
            flex-direction: column;
            height: 520px;
            padding: 0;
        }
    }

    &__title {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        text-transform: uppercase;
        transition: all .4s;
        text-align: left;
        align-self: center;


        @include mmin(768) {
            text-align: center;
            margin: 0 10px 35px;
        }

        @include mmin(1024) {
            font-size: 22px;
        }
    }

    &__content {
        display: none;
        text-align: left;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 19px;

        @include mmin(768) {
            font-size: 16px;
            line-height: 18px;
        }

        p:first-of-type {
            margin-top: 0;
        }

        iframe {
            width: 100%;
            height: 315px;

            @include mmin(768) {
                width: 560px;
            }
        }

    }

    &__button {
        border: none;
        height: 36px;
        background-color: transparent;
        padding: 0;
        transition: all .4s;
        align-self: center;
    }

    &__button svg {
        width: 36px;
        height: 36px;
        fill: #fff;

        transform: rotate(-90deg) scale(.7);

        @include mmin(768) {
            transform: rotate(180deg);
        }
    }

    &__items:hover {
        @include mmin(768) {
            background-color: rgba(0, 0, 0, .2);
            cursor: pointer;
        }
    }

    &__items:not(.product-description__items--open):hover .product-description__title {
        @include mmin(768) {
            margin-bottom: 25px;
            margin-top: -10px;
        }
    }

    &__items:not(:first-of-type):before {
        content: '';
        height: 1px;
        background-color: #fff;
        position: absolute;
        width: 100%;
        top: -1px;
        left: 0;

        @include mmin(768) {
            height: 100%;
            width: 5px;
            top: 0;
            left: -5px;
        }
    }

    &__items.product-description__items--collapse:before,
    &__items.product-description__items--open:before {
        @include mmin(768) {
            width: 2px;
            left: -2px;
        }
    }

    &__items--collapse {
        height: 31px;
        justify-content: flex-start;

        @include mmin(768) {
            height: auto;
            width: 5%;
            justify-content: flex-start;
        }
    }

    &__items--collapse .product-description__title {
        font-size: 14px;
        white-space: nowrap;
        margin: 0;

        @include mmin(768) {
            font-size: 16px;
            align-self: flex-start;
            text-align: left;
            transform: rotate(90deg);
            transform-origin: 0 150%;

        }
    }

    &__items--collapse .product-description__button {
        display: none;
    }

    &__items--open {
        background-color: rgba(0, 0, 0, .45);
        flex-direction: column;
        height: auto;

        @include mmin(768) {
            padding-left: 33px;
            padding-top: 22px;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__items--open:hover {
        @include mmin(768) {
            cursor: default;
        }
    }

    &__items--open:hover .product-description__button {
        @include mmin(768) {
            margin-top: -10px;
        }
    }

    &__items--open .product-description__title {
        font-size: 14px;
        line-height: 16px;
        align-self: flex-start;
        margin: 0 0 20px 0 ;

        @include mmin(768) {
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 35px;

        }
    }

    &__items--open .product-description__content {
        display: block;
    }

    &__items--open .product-description__button {
        align-self: flex-start;
    }

    &__items--open .product-description__button svg {
        transform: rotate(90deg) scale(.7);

        @include mmin(768){
            transform: rotate(0deg);
        }

    }

}

.textAnimation {
    animation: textAnimation .4s;
}


@keyframes textAnimation {
    0%, 90% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@import "product-slider";
