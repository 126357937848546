.popup-modal {
    background-color: rgba(0,0,0,.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;

    @include mmin($tablet) {
        display: block;
    }

    img {
        max-width: none;
        width: auto;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}