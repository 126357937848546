html {
  box-sizing: border-box;
 }  //min-width: 1200px

*, *::before, *::after {
    box-sizing: inherit; }

body {
  color: $main-color;
  background-color: #1f1f1f;
  background: linear-gradient(90deg, #1B1B1B 0%, #0B0B0B 100%);
  font-size: 100%;
  line-height: 1.2;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  overflow-x: hidden; }

a {
  color: $main-color;
  text-decoration: none; }

a:hover {
  cursor: pointer; }

//a:focus,
a:active {
  cursor: pointer; }

select,
option,
textarea,
input[type='text'],
input[type='email'],
input[type='tel'],
input[type='password'],
input[type='file'],
input[type='submit'],
button[type='button'],
button[type='submit'],
fieldset,
figcaption,
figure {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; }


input[type='button']:hover,
input[type='submit']:hover,
button:hover {
  cursor: pointer; }

img {
  max-width: 100%; }

// kill yellow in autocomplete
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.page-container {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  //&__wrap
  //max-width: 970px
 }  //margin: 0 auto

// если забыли заполнить или поставить alt
img[alt=""],
img:not([alt]) {
  border: 5px dashed #c00; }
