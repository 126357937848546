.nav {

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center; }

  &__item {
    padding: 0 15px;
    position: relative;

    //&.active
    //.nav__text, .nav__link
    //  color: $brand-color

    &:hover {
      .nav__link, .nav__text {
        color: $brand-color; } }

    &--open {
      .submenu {
        visibility: visible;
        opacity: 1;
        transition: all .3s ease-in-out; }

      .nav__text {
        color: $brand-color;

        svg {
          stroke: $brand-color;
          transform: rotate(180deg); } } } }

  &__link {
    text-decoration: none; }

  &__link, &__text {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: block;
    transition: color .3s; }

  &__text {
    position: relative;
    cursor: pointer; } }

.nav__item--submenu {
  &:hover {
    cursor: pointer;

    svg {
      stroke: $brand-color; } } }

@import "header-submenu";
