.product-card {
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);

  @include mmin($small) {
    width: calc(49% - 1%);
    margin-right: 1%; }

  @include mmin($tablet) {
    width: calc(33.3% - 1%); }

  @include mmin($tablet--hor) {
    box-shadow: none;
    margin-bottom: 10px;
    height: 390px; }

  &:nth-of-type(2n) {
    margin-right: 1%; }

  &:nth-of-type(3n) {
    margin-right: 0; }

  &.active {
    margin-right: 1%;

    &:nth-of-type(2n) {
      margin-right: 1%; }

    &:nth-of-type(3n) {
      margin-right: 0; } }

  &:hover {
    z-index: 3;

    @include mmin($tablet--hor) {
      .product-card__advanced {
        display: block; }

      .product-card__overlay {
        overflow: visible; }

      .product-card__overlay-box {
        position: absolute;
        z-index: 10;
        background-color: #fff;
        min-height: 390px;
        right: 0;
        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.25); }

      .product-card__overlay-box-i {
        position: relative; }

      .card-advanced__title {
        display: block; } } }

  &::before {
    content: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 20; }

  &--top {
    order: -2; }

  &--new {
    order: -1; }

  &__overlay {
    overflow: hidden;
    height: 100%; }

  &__link {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 30px;

    @include mmin(601) {
      flex-flow: row wrap; }

    @include mmin($tablet) {
      padding: 30px 15px; }

    @include mmin($tablet--hor) {
      padding: 30px 15px 0;
      margin: 0 auto 20px; } }

  &__thumb {
    margin: 0 auto;
    margin-bottom: 5px;
    align-self: center;
    object-fit: contain;
    height: 197px;
    max-width: 100%;

    @include mmin($tablet) {
      height: 250px; } }

  &__title {
    color: $main-color;
    font-size: 14px;
    font-weight: 300;
    line-height: 27px;
    margin: 0;
    text-align: center;

    @include mmin($tablet--hor) {
      font-size: 18px; } }

  &__advanced {
    padding: 0 19px 30px;

    @include mmin($tablet--hor) {
      display: none; } } }

@import "card-advanced";
