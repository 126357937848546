.certificate-modal {
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  overflow: scroll;
  overflow-x: hidden;
  display: none;

  &__gallery {
    margin: 10px auto;
    position: relative;
    width: 100%;

    @include mmin($extsmall) {
        margin: 50px auto;
    }

    @include mmin($tablet--hor) {
        width: 1000px;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-width: 700;
    font-size: 36px;

    width: 36px;
    height: 36px;
    display: inline-block;
    cursor: pointer;

    svg {
      fill: #fff;
    }
  }

  &__close:hover {
    svg {
      fill: $second-color;
    }
  }

  &__title {
    font-size: 30px;
    font-style: 500;
    line-height: 25px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }

  &__buttons {
    text-align: center;
    margin: 15px 0;
  }

  &__download {
    padding: 15px;
  }

  &__slider {}

  &__prev-slide,
  &__next-slide {
    position: absolute;
    top: 50%;
    border: 0;
    outline: none;
    cursor: pointer;

    width: 36px;
    height: 36px;
    padding: 0;
    background-color: transparent;

    svg {
      fill: #fff;
    }
  }

  &__prev-slide:hover,
  &__next-slide:hover {
    svg {
      fill: $second-color;
    }
  }

  &__prev-slide {
    left: 0;
  }

  &__next-slide {
    right: 0;
  }

  &__inner {

    overflow: hidden;
    margin: 0 auto;
    position: relative;

    width: 190px;
    height: 300px;

    @include mmin($extsmall) {
        width: 240px;
        height: 350px;
    }

    @include mmin($tablet) {
        width: 675px;
        height: 955px;
    }
  }

  &__list {
    @include list-unstyled;
    position: absolute;
    top: 0;
    left: 0;
    // transition: 0.5s;
  }

  &__item {

    display: inline-block;
    width: 190px;

    @include mmin($extsmall) {
        width: 240px;
    }

    @include mmin($tablet) {
        width: 675px;
    }
  }

  &__slide {
    width: 100%;
    max-width: 100%;
  }

  &__slide--product {
    display: inline-block;
    width: 190px;

    @include mmin($extsmall) {
      width: 240px;
    }

    @include mmin($tablet) {
      width: 500px;
    }
  }

  &__inner--product {

    overflow: hidden;
    margin: 0 auto;
    position: relative;

    width: 190px;

    @include mmin($extsmall) {
      width: 240px;
    }

    @include mmin($tablet) {
      width: 500px;
      height: 700px;
    }
  }

  &__dots {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  &__dot {
    width: 8px;
    height: 8px;
    background-color: #fff;
    display: inline-block;
    border-radius: 50%;
    transition: 0.4s;
    margin-right: 10px;
  }

  &__dot:last-of-type {
    margin-right: 0;
  }

  &__dot.active {
    width: 12px;
    height: 12px;
    background-color: $second-color;
  }
}
