.logo {
  &__image {
    max-width: 69px;
    height: 28px;
    vertical-align: middle;
    object-fit: contain;

    @include mmin($tablet--hor) {
      max-width: 100px;
      height: 40px; } } }
