.page-header {
  position: relative;
  z-index: 10; }

.header-items {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0;

  @include mmin($tablet--hor) {
    padding: 40px 0; }

  &--open {
    .mobile-menu {
      display: block;

      @include mmin($tablet--hor) {
        display: none; } }

    .mobile-menu__content {
      transform: translateX(0); } }

  //&__logo
  //margin-left: 9px

  &__nav {
    display: none;

    @include mmin($tablet--hor) {
      display: block; } }

  &__mobile-menu {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 12;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;

    @include mmin($tablet--hor) {
      display: none; } } }

@import "header-mobile-menu";
@import "header-logo";
@import "header-menu";
@import "user-options";
