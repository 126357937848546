.news-article {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &__latest-news {
    padding-bottom: 100px;

    @include mmin($tablet) {
      padding-bottom: 130px; } } }

.article {
  //margin-bottom: 60px

  &__content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center; }

  &__title {
    margin: 0 0 50px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: $main-color;
    text-align: center;

    @include mmin($tablet) {
      margin: 0 0 60px;
      font-size: 24px; } }

  &__img {
    width: 100%;
    object-fit: cover;
    display: block;
    margin-bottom: 50px;
    height: 200px;

    @include mmin($small) {
      height: auto;
      max-height: 600px; }

    @include mmin($tablet) {
      margin-bottom: 60px; } }

  &__text {
    font-size: 15px;
    letter-spacing: 0.02em;
    line-height: 24px;
    color: $main-color; }

  &__text-block {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    @include mmin($tablet) {
      margin-bottom: 60px;
      flex-flow: row nowrap; }

    div {
      width: 100%;
      color: $main-color;

      @include mmin($tablet) {
        width: calc(50% - 12px); }

      &:first-child {
        order: 1;

        @include mmin($tablet) {
          order: inherit; } }

      h2 {
        text-align: center;
        margin: 0 0 50px;
        font-size: 18px;
        font-weight: 700;

        @include mmin($tablet) {
          margin: 0 0 60px;
          font-size: 24px; } }

      p {
        line-height: 25px;
        font-size: 15px;
        letter-spacing: 0.02em;
        margin: 0 0 25px;

        @include mmin($tablet) {
          margin: 0 0 30px; }

        &:last-child {
          margin-bottom: 0; } } }

    img {
      display: block;
      height: 220px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 30px;

      @include mmin($tablet) {
        margin-bottom: 0;
        height: auto;
        min-height: 280px; } }

    &--reverse {
      flex-flow: row-reverse wrap;

      @include mmin($tablet) {
        flex-flow: row-reverse nowrap; } } } }
