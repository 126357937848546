.news {
  padding: 0 0 30px; }

.news-card-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.news-card {
  display: flex;
  flex-flow: column wrap;
  margin: 0 0 30px;
  width: 100%;
  background: #FFF;
  box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);
  transition: box-shadow .3s;

  @include mmin($tablet) {
    width: calc(50% - 12px);
    margin: 0 0 60px; }

  @include mmin($tablet--hor) {
    width: calc(50% - 10px); }

  &--latest {
    width: 100%;
    margin: 0 auto 30px;

    &:hover {
      box-shadow: 0 10px 20px rgba(136, 136, 136, 0.25); }

    @include mmin($small) {
      width: 85%; }

    @include mmin($tablet) {
      width: 48%; }

    @include mmin($tablet--hor) {
      width: calc(33.3% - 10px);
      margin: 0; }

    .news-card__content {
      height: 100%;

      a {
        display: flex;
        flex-flow: column wrap;
        height: 100%; } } }

  &__content {
    a {
      display: block; } }

  &__img {
    display: block;
    width: 100%;
    object-fit: contain;

    @include mmin($tablet--hor) {
      object-fit: cover;
      height: 306px; }

    &--latest {
      height: 178px;
      object-fit: cover;

      @include mmin($tablet--hor) {
        height: 250px; } } }

  &__title {
    color: $main-color;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    margin: 20px 0 17px;
    padding: 0 10px;
    text-transform: uppercase;
    text-align: center;

    @include mmin($tablet) {
      font-size: 18px;
      padding: 0 30px;
      margin: 28px 0; }

    &--latest {
      color: $main-color;
      margin: 0;
      padding: 20px 10px;
      font-size: 15px;
      line-height: 19px;
      font-weight: 500;
      text-align: center;
      transition: color .3s;
      text-transform: uppercase;

      @include mmin($tablet) {
        font-size: 15px; }

      @include mmin($tablet--hor) {
        margin: 0;
        padding: 30px 30px;
        font-weight: 500;
        text-transform: none; } } }


  //&__link--latest
  //&:hover
  //  .news-card__title--latest
  //    color: $brand-color

  &__text {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.02em;
    margin: 0 0 20px;
    padding: 0 10px;

    @include mmin($tablet) {
      font-weight: 400;
      margin: 0 0 30px;
      padding: 0 30px; } }

  &__date {
    display: none;
    font-size: 14px; }

  &__add {
    &--latest {
      margin-top: auto;
      padding: 0 8px;
      font-size: 13px;
      display: none; } }

  &__more {
    display: block;
    font-size: 15px;
    font-weight: 700;
    color: $main-color;
    text-decoration: underline;
    letter-spacing: 0.02em;
    margin-top: auto;
    margin-left: 10px;
    margin-bottom: 20px;
    transition: border .3s;
    border: 0;
    align-self: flex-start;

    @include mmin($tablet) {
      margin-left: 30px;
      margin-bottom: 30px; }

    &:hover {
      text-decoration: none; } }


  &__load-error {
    color: $main-color;
    text-align: center;
    display: none; } }

#show-more-form {
  margin: 18px 0 25px; }

.show-more-form__btn {
  margin: 0 auto;
  display: block;
  border: 0;
  background: none;
  color: $main-color;
  font-size: 18px;
  font-weight: 400;
  line-height: 38px;
  text-transform: uppercase;

  &:focus {
    outline: 0; }

  &:hover {
    cursor: pointer; } }

@import "news-article/news-article";
@import "news-latest";
