.submenu {
  overflow: hidden;
  background-color: #fff;
  transition: all .4s ease-in-out;
  color: $main-color;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 32px;
  left: 0;
  z-index: 15;
  margin: 0;
  cursor: default;
  box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);

  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-flow: column wrap;
    height: auto;

    @include mmin(768) {
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      text-align: center; } }

  &__item {
    width: 100%; }

  &__link, &__text {
    transition: background-color .3s;
    display: block;
    position: relative;
    line-height: 42px;
    height: 42px;
    white-space: nowrap;
    padding: 0 16px;
    background-color: #fff;

    &:hover {
      background-color: #E1E1E1; } }

  &__text {
    //color: $second-color

    &:hover {
      background-color: #fff; } } }
