.youtube-wrap {
  background-color: #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 190px;

  @include mmin($small) {
    height: 650px; }

  &__img {
    width: 100%;
    cursor: pointer;
    height: 100%;
    object-fit: cover; }

  &__play-button {
    width: 63px;
    height: 44px;
    background-color: #FF1C1C;
    //box-shadow: 0 0 30px rgba( 0,0,0,0.6 )
    z-index: 1;
    //opacity: 0.8
    border-radius: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
    transition: all .3s;

    @include mmin($small) {
      width: 89px;
      height: 62px;
      border-radius: 14px; }

    &:hover {
      background-color: #FF1C1C;
      opacity: .9; }

    &::before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 9px 0 9px 18px;
      border-color: transparent transparent transparent #fff;
      top: 50%;
      left: 50%;
      transform: translate3d( -50%, -50%, 0 );

      @include mmin($small) {
        border-width: 13px 0 13px 26px; } } }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; } }
