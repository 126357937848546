.faq {

  &__container {
    display: flex; }

  &-title {
    min-height: 121px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;

    &__text {
      color: #fff;
      align-self: center;
      text-transform: uppercase;
      letter-spacing: 2.15px;
      font-size: 30px;
      font-weight: 300;
      margin: 0 auto;

      @include mmin($small) {
        font-size: 43px; }

      @include mmin($tablet--hor) {
        padding-left: 35px;
        margin: 0; } } }

  &-content {
    margin-bottom: 50px;

    @include mmin($tablet) {
      margin-bottom: 60px; } }

  &-tabs-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 775px;
    margin: 0 auto; }

  &-questions {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    display: none;

    &.active {
      display: block; }

    @include mmin($small) {
      //width: 48%
      margin-bottom: 0; }

    @include mmin($tablet--hor) {
 }      //width: 400px

    &__title {
      margin: 0;
      padding: 12px 0;
      text-transform: uppercase;
      color: $main-color;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      margin-bottom: 10px;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        z-index: 10;
        background-color: #8a8c91;
        transition: background-color .4s; } } }

  &-questions-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid #E1E1E1;

    &:first-child {
      border-top-color: #E1E1E1; }

    &__item {
      color: $main-color;
      padding: 20px 0;
      position: relative;
      background-color: #fff;
      transition: all .2s;

      @include mmin($tablet) {
        padding: 31px 0; }

      &:hover {
        box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);
        margin: -2px 0;
        padding: 33px 0;
        z-index: 3;

        .faq-questions-list-title {
          &::after {
            box-shadow: none;
            border-color: #E1E1E1; } } }

      &.active {
        box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25);
        margin: -2px 0;
        padding: 20px 0;
        z-index: 2;

        @include mmin($tablet) {
          padding: 33px 0; }

        .faq-questions-list-title {
          &::after {
            content: '-';
            box-shadow: none;
            border-color: #E1E1E1; } } } }

    &-title {
      position: relative;
      margin: 0;
      padding-right: 20px;
      padding-left: 20px;
      cursor: pointer;
      font-size: 15px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include mmin($tablet) {
        font-size: 18px;
        padding-right: 40px;
        padding-left: 40px;
        flex-flow: row nowrap;
        justify-content: space-between;
        text-align: left; }

      &::after {
        content: '+';
        position: relative;
        width: 40px;
        height: 40px;
        z-index: 10;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid transparent;
        line-height: 1;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #727272;
        box-shadow: 0 3px 6px rgba(146, 146, 146, 0.25);
        transition: all .2s;
        margin-top: 20px;

        @include mmin($tablet) {
          margin-top: 0; } } }

    &-content {
      display: none;
      padding: 30px 20px 0 20px;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.02em;

      @include mmin($tablet) {
        padding: 30px 40px 0 40px; }

      ul {
        list-style-type: disc;
        padding-left: 20px;

        @include mmin($tablet) {
          padding-left: 40px; } }

      p {
        margin: 0 0 10px 0; } } } }

@import "faq-tabs";
@import "faq-form/faq-form";
