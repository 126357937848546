.page-footer {
  &__wrap {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;

    @include mmin($tablet) {
      padding: 74px 15px;
      justify-content: space-between; } }

  &__menu {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin: 0 0 50px;
    padding: 0;
    list-style: none;

    @include mmin($tablet) {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      width: initial;
      margin: 0; } }

  &__copyright {
    color: #fff;
    font-size: 18px;
    font-weight: 300;

    @include mmin($tablet) {
      padding-bottom: 0;
      font-size: 15px;
      font-weight: 500; } } }

@import "footer-menu";
