.product__text-block {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  @include mmin($tablet) {
    margin-bottom: 60px;
    flex-flow: column wrap; }

  div {
    width: 100%;
    color: $main-color;
    margin-bottom: 50px;

    @include mmin($tablet) {
      margin-bottom: 60px; }

    &:nth-of-type(2) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      margin-bottom: 0; }

    h2 {
      text-align: center;
      margin: 0 0 50px;
      font-size: 18px;
      font-weight: 700;

      @include mmin($tablet) {
        margin: 0 0 60px;
        font-size: 24px; } }

    p {
      line-height: 25px;
      font-size: 15px;
      letter-spacing: 0.02em;
      margin: 0 0 25px;

      @include mmin($tablet) {
        margin: 0 0 30px; }

      &:last-child {
        margin-bottom: 0; } }

    a {
      position: relative;
      display: flex;
      width: 100%;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0; }

      @include mmin($tablet) {
        width: calc(33.3% - 10px);
        margin-bottom: 0; }

      span {
        position: absolute;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        align-self: flex-end;
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        z-index: 10;

        @include mmin($tablet) {
          padding-bottom: 30px;
          font-size: 24px;
          font-weight: 500; } }

      img {
        height: 200px;
        width: 100%;
        object-fit: cover;

        @include mmin($tablet) {
          height: 540px; } }

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.67) 126.66%); } } } }
