.search-result {
  min-height: calc(100vh - 287px);
  padding-top: 60px;

  &__heading {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: $main-color; }

  h2 {
    margin: 0;
    padding-bottom: 60px; }

  &__title {
    font-weight: 400; }

  &__text-result {
    font-weight: 500; }

  &__products,
  &__news,
  &__certificates {
    margin-bottom: 12px; } }

.search-page {
  margin-top: 50px;
  margin-bottom: 50px;

  @include mmin($tablet) {
    margin-top: 60px;
    margin-bottom: 60px; }

  &__form-block {
    display: flex; }

  &__input-box {
    display: flex; }

  &__form {
    margin-right: 20px; }

  &__input {
    border: 1px solid #E1E1E1;
    height: 50px;
    width: 100%;
    padding: 0 25px;
    color: $main-color;
    font-size: 15px;
    font-weight: 300;

    @include mmin($small) {
      width: 376px; } }

  &__button {
    width: 79px;
    height: 50px;
    background-color: $brand-color;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      vertical-align: middle;
      width: 18px;
      height: 18px;
      fill: $main-color; } } }
