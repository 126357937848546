.our-contacts {
  padding-bottom: 86px;

  @include mmin($tablet) {
    padding-bottom: 130px; }

  &__title-contacts {
    text-align: center;
    margin: 0 0 50px;
    color: $main-color;
    font-weight: 700;
    font-size: 18px;

    @include mmin($tablet) {
      margin: 0 0 60px;
      font-size: 24px; } }

  //&__wrap
  //display: flex
  //flex-flow: row wrap
  //justify-content: space-between
  //align-items: flex-start

  &__map {
    width: 100%;
    height: 500px;

    //+mmin($tablet)
 }    //width: 64%

  &__address {
    width: 80%;
    color: $main-color;
    display: flex;
    flex-flow: row wrap;
    margin: 25px auto;

    @include mmin(768) {
      width: 32%;
      margin: 0; } } }

.address {
  color: $main-color;
  font-style: normal;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;

  @include mmin($tablet) {
    align-items: flex-start; }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px;
    font-style: normal;

    @include mmin($tablet) {
      font-size: 24px; } }

  &__street {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 50px;

    @include mmin($tablet) {
      font-size: 18px;
      margin: 0 0 60px; } } }

@import 'employee-contacts/employee-contacts';
@import 'contact-us/contact-us';
@import 'feedback/feedback';
