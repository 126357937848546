.product-page {
  padding-bottom: 60px;

  @include mmin($tablet) {
    padding-bottom: 120px; }

  &__product {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    color: $main-color;
    font-size: 16px; }

  &__title {
    width: 100%;
    color: $main-color;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 25px;

    @include mmin($tablet) {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 25px;
      text-align: left; } }

  &__photo {
    object-fit: contain;
    width: 100%;
    height: 374px;
    max-width: 100%;
    margin: 0 auto;

    @include mmin(768) {
      width: 328px;
      margin: initial; } }

  &__slider {
    width: 51%; }

  &__description {
    width: 100%;

    @include mmin(768) {
      width: 41%; } }

  &-desc {
    &__text {
      margin-bottom: 30px;

      @include mmin($tablet) {
        margin-bottom: 60px; }

      p {
        margin: 0 0 30px;
        color: $main-color;
        line-height: 26px;
        font-size: 15px;
        letter-spacing: 0.02em;

        @include mmin($tablet) {
          font-size: 16px;
          margin: 0 0 25px; } }

      strong {
        font-size: 18px;
        font-weight: 700;
        display: inline-block; }

      ul {
        margin-top: 0;
        padding-left: 20px;

        @include mmin($tablet) {
          padding-left: 40px; }

        li {
          margin: 10px 0; } } }

    &__btn {
      margin: 0;
      margin-top: 10px; }

    h3 { //:TODO переписать коли заповнять контентом
      margin: 0;
      margin-bottom: 14px;
      font-weight: 500;
      text-transform: uppercase; } }

  &__advantages {
    line-height: 24px;
    width: 100%;
    margin-top: 50px;

    @include mmin(768) {
      margin-top: 60px; } }

  &__advantage-info {
    margin-top: 50px;

    @include mmin($tablet) {
      margin-top: 60px; } }

  &__delivery {
    margin: 30px 0 50px; } }

.product-delivery-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__item {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0; }

    @include mmin(768) {
      width: calc(33.3% - 40px);
      margin-right: 40px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0; } } } }

.product-card--top {
  .product-badges {
    background-color: #FF5D00; } }

.product-card--new {
  .product-badges {
    background-color: #70C36F; } }

@import "product-category-choose";
@import "product-badges";
@import "product-description/product-description";
@import "product-description/modal";
