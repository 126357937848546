.site-main {
  &__banner-block {
    position: relative;
    background-color: $main-color;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -84px;
    padding-top: 84px;
    padding-bottom: 50px;
    order: -1;
    min-height: 500px;
    display: flex;
    flex-flow: column wrap;
    background-position: center bottom;

    @include mmin($tablet) {
      justify-content: center;
      min-height: 600px;
      margin-top: -120px;
      padding-top: 170px;
      padding-bottom: 100px;
      background-position: center; }

    //&::before
    //content: ''
    //width: 100%
    //height: 100%
    //position: absolute
    //display: block
    //background-color: rgba(16, 16, 16, 0.7)
    //left: 0
 } }    //top: 0

.main-banner-block {
  &--partnership {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      display: block;
      background-color: rgba(16, 16, 16, 0.6);
      left: 0;
      top: 0;

      @include mmin($tablet) {
        content: none; } }

    .main-banner-block__text {
      max-width: 430px; } }

  &__container {
    //margin-top: 80px
    position: relative;
    text-align: center;

    @include mmin($tablet) {
      text-align: left; } }

  &__title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
    margin-top: 0;

    @include mmin($tablet) {
      margin-bottom: 90px;
      font-size: 36px; } }

  &__text {
    color: #fff;
    line-height: 26px;
    font-size: 18px;
    letter-spacing: 0.02em;
    margin: 0;
    text-align: center;

    @include mmin($tablet) {
      text-align: left;
      max-width: 530px; } }

  ul {
    margin: 0;

    li {
      margin-bottom: 15px; } } }
