.site-main {
  background-color: #fff;

  &--index {
    background-color: transparent;
    margin-top: -120px; }

  &--noindex {
    display: flex;
    flex-flow: column wrap; } }

.main {
  &-banner {
    padding-top: 120px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: auto 765px;

    @include mmin($tablet) {
      background-position: right top;
      background-size: cover; }

    @include mmin($tablet) {
      padding-top: 150px; }

    @include mmin($tablet--hor) {
      padding-top: 178px;
      background-size: contain; }

    &__container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center; }

    &__photo {
      width: 35%;
      display: none;

      @include mmin($tablet) {
        display: block; } }

    &__content {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      width: 100%;
      padding-top: 20px;

      @include mmin($tablet) {
        padding-top: 0;
        width: 60%;
        align-items: stretch; } }

    &-content {
      p {
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 15px;
        color: #fff;
        text-align: center;

        @include mmin($tablet) {
          font-size: 27px;
          margin-bottom: 15px;
          text-align: left; } }

      h1 {
        color: #fff;
        font-weight: 700;
        font-size: 24px;
        margin: 0 0 15px;
        text-align: center;

        @include mmin($tablet) {
          text-align: left;
          font-size: 35px;
          margin-bottom: 15px; } }

      h2 {
        font-weight: 500;
        font-size: 18px;
        display: block;
        color: #fff;
        margin: 0 0 34px;

        @include mmin($tablet) {
          font-size: 24px;
          margin: 0 0 90px; } }

      img {
        margin-bottom: 20px;
        width: 217px;
        height: 217px;
        object-fit: contain;

        @include mmin($tablet) {
          display: none; } } } }

  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 50px;
    text-align: center;

    @include mmin($tablet) {
      font-size: 24px;
      text-align: left; } }

  &-brand {
    margin-top: 50px;
    margin-bottom: 50px;

    @include mmin($tablet) {
      margin-top: 65px;
      margin-bottom: 100px; }

    &__text {
      font-size: 16px;
      line-height: 20px; } }

  &-how-working {
    padding: 50px 0;
    background-image: url("../img/how-work-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;

    @include mmin($tablet) {
      padding: 67px 0 90px; }

    //&::before
    //content: ''
    //width: 100%
    //height: 100%
    //position: absolute
    //display: block
    //background-color: rgba(17, 17, 17, 0.84)
    //left: 0
    //top: 0

    &__title {
      margin-bottom: 50px;
      position: relative;

      @include mmin($tablet) {
        margin-bottom: 105px; } } }

  &-latest-news {
    margin-top: 65px;
    padding-bottom: 30px;

    .latest-news__title {
      color: #fff;
      margin-bottom: 42px;
      text-align: center;
      font-size: 18px;

      @include mmin($tablet) {
        text-align: left;
        font-size: 24px; } }

    .news-card {
      background-color: transparent;
      box-shadow: none; }

    .news-card__title--latest {
      color: #fff;
      padding: 30px 15px; }

    .news-card__link--latest {
      &:hover {
        .news-card__title--latest {
          color: $brand-color; } } } } }

@import "index-disc-block";
@import "index-about-brand";
@import "how-working";

