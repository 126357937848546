.user-options {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center; }

  &__link {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    display: block;
    transition: color .3s;

    &:hover {
      color: $brand-color; }

    &--phone {
      display: none;

      @include mmin($tablet--hor) {
        display: block;
        font-weight: 700; } } }

  &__icon {
    max-width: 20px; }

  &__hamburger {
    //margin-left: 8px
    position: relative;
    z-index: 15; }

  .btn--header {
    display: none;

    @include mmin($tablet--hor) {
      display: block; } } }

.hamburger-menu {
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;

  @include mmin($tablet--hor) {
    display: none; }

  &--open {
    color: #fff;

    .hamburger-menu__icon {
      background: transparent;

      &::before {
        transform: rotate(-45deg);
        background: #fff;
        bottom: 0;
        transition: bottom 300ms cubic-bezier(.23,1,.32,1),transform 300ms cubic-bezier(.23,1,.32,1); }

      &::after {
        transform: rotate(45deg);
        background: #fff;
        top: 0;
        transition: top 300ms cubic-bezier(.23,1,.32,1),transform 300ms cubic-bezier(.23,1,.32,1); } } }

  &__icon {
    display: block;
    position: relative;
    background: #fff;
    transition: all .3s;
    width: 32px;
    height: 2px;

    &::before, &::after {
      content: '';
      width: 32px;
      height: 2px;
      position: absolute;
      left: 0;
      background: #fff; }

    &::before {
      bottom: 8px;
      transition: bottom 300ms 300ms cubic-bezier(.23,1,.32,1),transform 300ms cubic-bezier(.23,1,.32,1); }

    &::after {
      top: 8px;
      transition: top 300ms 300ms cubic-bezier(.23,1,.32,1),transform 300ms cubic-bezier(.23,1,.32,1); } } }

.page-header__lang {
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  position: relative;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin: 0 36px;
  cursor: pointer; }

.lang {
  display: none;

  @include mmin($tablet--hor) {
    display: flex; }

  &:hover {
    .lang__active {
      color: $brand-color; }

    svg {
      stroke: $brand-color; } }

  &--open {
    .lang__active {
      color: $brand-color; }

    svg {
      transform: rotate(180deg);
      stroke: $brand-color; }

    .lang-list {
      visibility: visible;
      opacity: 1; } }

  &__active {
    //display: flex
    margin-right: 7px;
    cursor: pointer; }

  &__list {
    position: absolute;
    list-style: none;
    padding: 0;
    right: 0;
    top: 32px;
    opacity: 0;
    z-index: 5;
    visibility: hidden;
    transition: 0.4s;
    background-color: #fff;
    box-shadow: 0 2px 15px rgba(146, 146, 146, 0.25); }

  &-list {

    &__link, &__active {
      transition: background-color .3s;
      display: block;
      position: relative;
      line-height: 42px;
      height: 42px;
      white-space: nowrap;
      padding: 0 20px;
      background-color: #fff;
      text-align: center;
      color: $main-color;

      &:hover {
        background-color: #E1E1E1; } }

    &__active {
      cursor: default;
      font-weight: 700;
      display: none;

      &:hover {
        background-color: #fff; } } } }

.search {
  margin-right: 33px;

  @include mmin($tablet--hor) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    padding: 0 8px;
    position: relative;
    margin-right: 0; }

  &--full {
    .search__form {
      transform: scaleY(1);
 }      //transition: all .4ms

    .search__button {
      z-index: 10;

      svg {
        fill: $main-color;

        &:hover {
          fill: $main-color; } } } }

  &__icon svg {
    fill: #fff;
    transition: all .4s;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    &:hover {
      fill: $brand-color; } }

  &__button {
    padding: 0;
    border: 0;
    background-color: transparent;
    transition: .4s;
    outline: 0;
    cursor: pointer;
    width: 20px;
    height: 20px; }

  &__form {
    position: absolute;
    z-index: 5;
    background-color: #fff;
    margin: 0;
    padding: 0;
    overflow: hidden;
    //transition: all .4s
    right: 0;
    transform: scaleY(0);
    display: none;

    @include mmin($tablet--hor) {
      display: block; } }

  &__input-box {
    display: flex;
    height: 30px; }

  &__input {
    margin: 0;
    padding: 0 32px 0 16px;
    font-size: 14px;
    font-weight: 400;
    color: $main-color;
    border: none;
    transition: all .3s;
    width: 271px;
    height: 30px;
    line-height: 30px;
    text-align: left;
    background-color: #fff;
    align-self: center; } }

.close-search {
  background-color: transparent;
  border: 0;
  padding: 0;
  position: relative;
  display: none;
  right: 0;
  top: 0;
  margin-top: -3px;

  svg {
    vertical-align: middle; } }

.search-open {
  @include mmin($tablet--hor) {
    position: relative;

    .user-options__item {
      &:nth-child(1) {
        width: 36px; } }

    .search {
      position: static; }

    .search__form {
      margin-right: 38px;
      width: calc(100% - 38px); }

    .search__button {
      position: absolute;
      right: 44px; }

    .close-search {
      display: block;
      position: absolute; }

    .lang {
      visibility: hidden; }

    .phone {
      visibility: hidden; } } }
