.product-badges {
  width: 144px;
  height: 33px;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mmin($tablet--hor) {
    width: 178px;
    height: 40px;
    font-size: 18px; } }
