.how-working-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  //counter-reset: issues 0
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: #fff;

  &__item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0; }

    @include mmin($small) {
      width: 50%; }

    @include mmin($tablet) {
      width: calc(33.3% - 80px); }

    @include mmin($tablet--hor) {
      margin-bottom: 0; } }

  &__img {
    margin-bottom: 30px;

    @include mmin($tablet) {
      margin-bottom: 50px; } }

  .working-img-1,
  .working-img-2,
  .working-img-3 {
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    background-size: contain; }

  .working-img-1 {
    background-image: url("../img/work-1.png"); }

  .working-img-2 {
    background-image: url("../img/work-2.png"); }

  .working-img-3 {
    background-image: url("../img/work-3.png"); }

  &__text {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    max-width: 278px;

    @include mmin($tablet) {
      max-width: 100%; } } }

//&::before
//counter-increment: issues 1
//content: counter(issues, decimal) ". "
//display: inline-block
//text-align: center
